import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useLanguage } from '../LanguageContext';

import logo from '../assets/images/omelet-icon-gradient.png';
import textLogo from '../assets/images/oaasis.png';

function Header() {
    const { language, toggleLanguage } = useLanguage();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const oppfontClass =
        language === 'en'
            ? 'font-NotoSansKR font-medium'
            : 'font-MerriweatherSans font-l';

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div className="flex items-center justify-between p-2 sticky top-0 drop-shadow bg-white z-50 w-full">
            <div className="flex items-center">
                <img
                    src={logo}
                    alt="logo"
                    className="ml-3 mr-2 hidden sm:inline w-[32px] h-[32px] cursor-pointer"
                    onClick={() => (window.location.href = '/')}
                />
                <img
                    src={textLogo}
                    alt="OaaSIS"
                    className="ml-3 mr-5 hidden md:inline w-[86px] h-auto cursor-pointer"
                    onClick={() => (window.location.href = '/')}
                />
            </div>
            <nav className="flex items-center space-x-5 sm:space-x-12 mr-3 font-MerriweatherSans font-l text-[14px]">
                <button
                    onClick={toggleLanguage}
                    className={`relative text-[10pt] text-omelet_purple group w-max hover:underline ${oppfontClass}`}
                >
                    {language === 'ko' ? 'English' : '한국어'}
                </button>

                {/* Product Dropdown */}
                <div className="relative">
                    <button
                        onClick={toggleDropdown}
                        className="text-sm text-gray1 group w-max flex items-center"
                    >
                        Product
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="ml-1 inline-block"
                        >
                            <path d="M7 10l5 5 5-5" />
                        </svg>
                    </button>
                    {dropdownOpen && (
                        <div className="absolute bg-white shadow-lg border mt-2 rounded-md w-40">
                            <Link
                                to="/tms"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-omelet_purple hover:text-white"
                            >
                                TMS
                            </Link>
                            <Link
                                to="/routing"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-omelet_purple hover:text-white"
                            >
                                Routing Engine
                            </Link>
                        </div>
                    )}
                </div>

                <Link
                    onClick={() =>
                        window.open(
                            'https://omelet.ai/',
                            '_blank',
                            'noopener,noreferrer',
                        )
                    }
                    className="relative text-sm text-gray1 group w-max flex items-center space-x-1"
                >
                    <span>Company</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="inline-block"
                    >
                        <path d="M5 19L19 5" />
                        <path d="M5 5h14v14" />
                    </svg>
                    <span className="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:left-0 group-hover:w-1/2"></span>
                    <span className="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:right-0 group-hover:w-1/2"></span>
                </Link>
            </nav>
        </div>
    );
}

export default Header;
