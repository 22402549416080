'use client';

import Header from '../components/Header';
import { useLanguage } from '../../LanguageContext';
import {
    Card,
    CardHeader,
    CardTitle,
    CardDescription,
    CardContent,
} from '../../components/ui/Card';
import { Label } from '../../components/ui/Label';
import { Input } from '../../components/ui/Input';
import { Button } from '../../components/ui/ContactButton';
import { useState } from 'react';
import Footer from '../components/Footer';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

function RoutingContact() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    const handleSendEmail = () => {
        const mailtoLink = `mailto:info@omelet.ai?subject=${encodeURIComponent(
            title,
        )}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    const send_btn = (
        <Button
            type="submit"
            onClick={handleSendEmail}
            className="relative group w-full text-[16px] bg-omelet_purple border border-omelet_purple
         hover:bg-white hover:text-omelet_purple"
        >
            <span className="transition-transform duration-300 transform group-hover:-translate-x-8">
                {translations.send_message}
            </span>
            <span className="absolute right-4 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                →
            </span>
        </Button>
    );

    return (
        <>
            <Header />
            <div className="font-MerriweatherSans flex flex-col min-h-screen">
                <div className="grid grid-cols-1 lg:grid-cols-banner1 flex-grow sm:h-screen">
                    <div className="flex flex-col items-center justify-center">
                        <div className="w-full max-w-[90%] lg:max-w-[66%] text-start">
                            <p className="font-md text-[36px] md:text-[48px] leading-tight mb-10">
                                {translations.have_questions}
                            </p>
                            <p className="font-m text-[18px] mb-8">
                                {translations.assistance_offer}
                            </p>
                            {translations.support_reasons.map(
                                (reason, index) => (
                                    <p
                                        key={index}
                                        className="font-l text-[16px] mb-3 text-gray-700"
                                    >
                                        <span className="font-bl text-omelet_purple">
                                            {index + 1}&nbsp;&nbsp;
                                        </span>
                                        {reason}
                                    </p>
                                ),
                            )}
                            <p className="font-m text-[16px]">
                                {translations.contact_info}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center bg-[#FAFAFA]">
                        <div className="w-full max-w-full lg:max-w-[50%]">
                            <Card className="drop-shadow-lg">
                                <CardHeader>
                                    <CardTitle className="text-[32px]">
                                        {translations.contact_us}
                                    </CardTitle>
                                    <CardDescription>
                                        info@omelet.ai
                                    </CardDescription>
                                </CardHeader>
                                <CardContent className="space-y-2">
                                    <div className="grid grid-cols-1 gap-1">
                                        <Label
                                            className="text-sm text-[#808080] font-l"
                                            htmlFor="title"
                                        >
                                            {translations.title_label}
                                        </Label>
                                        <Input
                                            className="mb-3"
                                            id="title"
                                            type="text"
                                            value={title}
                                            onChange={(e) =>
                                                setTitle(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-1">
                                        <Label
                                            className="text-sm text-[#808080] font-l"
                                            htmlFor="message"
                                        >
                                            {translations.message_label}
                                        </Label>
                                        <textarea
                                            className="mb-3 border border-input h-32 px-3 py-2 text-sm"
                                            id="body"
                                            value={body}
                                            onChange={(e) =>
                                                setBody(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>{send_btn}</div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default RoutingContact;
