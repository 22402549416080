import CardWhyOaasis from './ui/CardWhyOaasis';
import { useLanguage } from '../../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

import ufImage from '../assets/images/uf.jpg';
import pfImage from '../assets/images/pf.jpg';
import userImage from '../assets/images/user.jpg';

function WhyOaasis() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const {
        uf_title,
        uf_txt,
        pf_title,
        pf_txt,
        user_title,
        user_txt,
        why_oaasis,
    } = translations;

    const fontClassTitle = 'font-MerriweatherSans font-l';

    const cards = [
        { image: ufImage, title: uf_title, details: uf_txt },
        { image: pfImage, title: pf_title, details: pf_txt },
        { image: userImage, title: user_title, details: user_txt },
    ];

    return (
        <div className="items-center w-full py-[2%] px-[5%]">
            <div className="w-full xl:w-[1280px] mx-auto">
                <div className={`flex flex-col ${fontClassTitle} text-[36px]`}>
                    {why_oaasis}
                </div>
                <div className="flex flex-col items-center justify-center my-16">
                    <div className="grid lg:grid-cols-3 gap-8 w-full xl:w-[1280px]">
                        {cards.map((card, index) => (
                            <div key={index} className="mx-auto">
                                <CardWhyOaasis
                                    image_url={card.image}
                                    title={card.title}
                                    details={card.details}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <hr className="border-gray-200" />
        </div>
    );
}

export default WhyOaasis;
