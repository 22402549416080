import './global.css';

import Header from './tms/components/Header';
import Banner from './tms/components/Banner';
import WhyOaasis from './tms/components/WhyOaasis';
import Architecture from './tms/components/Architecture';
import Footer from './tms/components/Footer';
import ExclusiveFeatures from './tms/components/ExFeatures';
import HowItWorks from './tms/components/HowItWorks';

function MainTms() {
    return (
        <div className="font-MerriweatherSans flex flex-col items-center min-h-screen bg-white">
            <Header />
            <Banner />
            <div className="w-full">
                <WhyOaasis />
                <HowItWorks />
                <ExclusiveFeatures />
                <Architecture />
            </div>
            <Footer />
        </div>
    );
}

export default MainTms;
