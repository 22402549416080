import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

import Main from './Main';

import MainRouting from './MainRouting';
import RoutingPricing from './routing/page/RoutingPricing';
import RoutingContact from './routing/page/RoutingContact';

import MainTms from './MainTms';
import TmsPricing from './tms/page/TmsPricing';
import TmsContact from './tms/page/TmsContact';

import reportWebVitals from './reportWebVitals';
import { LanguageProvider } from './LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LanguageProvider>
            <BrowserRouter basename="/">
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/routing/" element={<MainRouting />} />
                    <Route
                        path="/routing/pricing"
                        element={<RoutingPricing />}
                    />
                    <Route
                        path="/routing/contact"
                        element={<RoutingContact />}
                    />
                    <Route path="/tms/" element={<MainTms />} />
                    <Route path="/tms/pricing" element={<TmsPricing />} />
                    <Route path="/tms/contact" element={<TmsContact />} />
                </Routes>
            </BrowserRouter>
        </LanguageProvider>
    </React.StrictMode>,
);

reportWebVitals();
