import CardWhyOaasis from './ui/CardWhyOaasis';
import { useLanguage } from '../../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

import world from '../assets/images/world.png';
import intuitive from '../assets/images/intuitive.png';
import data from '../assets/images/data.png';

function WhyOaasis() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const cardData = [
        {
            image: world,
            title: translations.world_title,
            details: [
                translations.world_txt_1,
                translations.world_txt_2,
                translations.world_txt_3,
            ],
        },
        {
            image: intuitive,
            title: translations.intuitive_title,
            details: [
                translations.intuitive_txt_1,
                translations.intuitive_txt_2,
                translations.intuitive_txt_3,
            ],
        },
        {
            image: data,
            title: translations.data_title,
            details: [
                translations.data_txt_1,
                translations.data_txt_2,
                translations.data_txt_3,
            ],
        },
    ];

    return (
        <div className="items-center w-full py-8 px-[5%] box-border">
            <div className="w-full max-w-[1440px] mx-auto">
                {/* Header Section */}
                <div className="flex flex-col text-[18px] mb-2">
                    <span className="text-omelet_purple">WHY OaaSIS?</span>
                </div>
                <div className="flex flex-col text-[36px] mb-16">
                    {translations.why_oaasis}
                </div>

                {/* Cards Section */}
                <div className="grid xl:grid-cols-3 w-full gap-6">
                    {cardData.map((card, index) => (
                        <div
                            key={index}
                            className="mx-auto transform transition-transform duration-300 hover:scale-110 will-change-transform"
                        >
                            <CardWhyOaasis
                                image_url={card.image}
                                title={card.title}
                                details={card.details.map((detail, idx) => (
                                    <span key={idx}>
                                        {detail}
                                        <br />
                                    </span>
                                ))}
                            />
                        </div>
                    ))}
                </div>

                {/* Footer Section */}
                <div className="mt-16">
                    <hr className="w-full max-w-[1440px] bg-black h-[1px] border-none mx-auto" />
                </div>
            </div>
        </div>
    );
}

export default WhyOaasis;
