import './global.css';

import Header from './routing/components/Header';
import Banner from './routing/components/Banner';
import ApiService from './routing/components/ApiService';
import WhyOaasis from './routing/components/WhyOaasis';
import HowOaasis from './routing/components/HowOaasis';
import Footer from './routing/components/Footer';

function MainRouting() {
    return (
        <div className="font-MerriweatherSans flex flex-col items-center min-h-screen bg-white">
            <Header />
            <Banner />
            <div className="w-full">
                <ApiService />
                <WhyOaasis />
                <HowOaasis />
            </div>
            <Footer />
        </div>
    );
}

export default MainRouting;
